import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"

import { mobileWidth } from "../utils/common-styles"
import LayoutBlog from "../components/layout-blog"
import colors from "../utils/colors"
import SEO from "../components/seo"
import ogImage from "../images/image_softwareentwicklerfinden.png"

const Container = styled.div`
  padding: 10px 0;
`

const Posts = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-top: 40px;
`

const Post = styled(Link)`
  
  text-decoration: none;
  display: block;
  margin-bottom: 20px;
  width: 200px;
  margin-right: 30px;
  color: ${colors.BLACK_TEXT};
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  text-align: center;
`

const PostTitle = styled.h3`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  color: ${colors.DARK_BLUE};
`

const PostImage = styled.img`
  display: block;
  border-raduis: 10px;
  margin-bottom: 10px;
`

const PostText = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;
`

const Tags = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 30px 0;
  border-bottom: 2px solid ${colors.LIGHT_GRAY};
`

const Tag = styled.li`
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 18px;
  color: ${colors.GRAY_TEXT};
  margin: 0 40px -2px 0;
  padding-bottom: 10px;
  cursor: pointer;
  &:hover {
    color ${colors.DARK_BLUE};
  }
  ${({ current }) =>
    current &&
    css`
    color ${colors.DARK_BLUE};
    border-bottom: 2px solid ${colors.GREEN};
    @media (max-width: ${mobileWidth}px) {
      border: none;
    }
  `}
`

const PostItems = ({ posts }) =>
  posts.map(
    ({
      id,
      slug,
      title,
      image: {
        title: imageTitle,
        fluid: { src },
      },
      abstract,
      text: {
        childMarkdownRemark: { html: textHtml },
      },
    }) => {
      const abstractHtml = abstract
        ? abstract.childMarkdownRemark.html
        : textHtml

      return (
        <Post key={id} to={`/blog/${slug}/`}>
          {/* <PostImage src={src} alt={imageTitle} /> */}
          <PostTitle>{title}</PostTitle>
          {/* <PostText dangerouslySetInnerHTML={{ __html: abstractHtml }} /> */}
        </Post>
      )
    }
  )

const Blog = ({ path }) => {
  const [currentTag, setCurrentTag] = useState("all")
  const { items, tags } = useStaticQuery(getPosts)

  const allTags = [
    {
      tag: "all",
      label: "Visa allt",
    },
    ...tags.nodes,
  ]

  const handleClick = tag => () => {
    setCurrentTag(tag)
  }

  const tagLinks = allTags.map(({ tag, label }) => (
    <Tag
      key={tag}
      current={tag === currentTag ? 1 : 0}
      onClick={handleClick(tag)}
    >
      {label}
    </Tag>
  ))

  const filteredPosts = items.nodes.filter(post => {
    if (currentTag === "all") {
      return true
    }

    return post.tags && post.tags.map(({ tag }) => tag).includes(currentTag)
  })

  return (
    <LayoutBlog path={path}>
      <SEO title="Blog" description="Blog" image={ogImage} />
      <Container>
        {/* <Tags>{tagLinks}</Tags> */}
   
        <Posts>
          <PostItems posts={filteredPosts} />
        </Posts>
      </Container>
    </LayoutBlog>
  )
}

const getPosts = graphql`
  {
    items: allContentfulPost(
      limit: 1000
      sort: { fields: [createdAt], order: DESC }
    ) {
      totalCount
      nodes {
        id
        slug
        title
        image {
          title
          fluid(maxHeight: 150, maxWidth: 200, resizingBehavior: SCALE) {
            src
          }
        }
        abstract {
          childMarkdownRemark {
            html
          }
        }
        text {
          childMarkdownRemark {
            html
          }
        }
        tags {
          tag
        }
      }
    }
    tags: allContentfulTag(sort: { fields: [label], order: ASC }) {
      nodes {
        tag
        label
      }
    }
  }
`

export default Blog
