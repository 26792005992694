import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import colors from "../utils/colors"
import { outerStyles, innerStyles, Title, Subtitle } from "../utils/common-styles"

const Container = styled.section`
  ${outerStyles}
  background: ${colors.BLUE};
`

const Inner = styled.div`
  ${innerStyles}
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 60px;
`

const SubHeader = ({ title, subtitle }) => (
  <Container>
    <Inner>
      <Title>{ title }</Title>
      <Subtitle>{ subtitle }</Subtitle>
    </Inner>
  </Container>
)

SubHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}

export default SubHeader
