
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "typeface-muli"

import Header from "./header"
import Footer from "./footer"
import SubHeader from "./sub-header"
import GlobalStyles from "./global-styles.js"
import { innerStyles } from "../utils/common-styles"

const Container = styled.div`
  ${innerStyles}
  
`

const LayoutBlog = ({ children, path, title }) => (
  <>
    <Header path={path} />
    {/* <SubHeader
      title="Rekryteringsbloggen"
      subtitle="Artiklar och guider som hjälper dig hitta din nästa techtalang"
    /> */}
    <Container>
      <main>{children}</main>
    </Container>
    <Footer />
    <GlobalStyles />
  </>
)

LayoutBlog.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string,
}

export default LayoutBlog
